.video {
  position: relative;
  padding-bottom: 2rem;
  background: $c-background;

  .grid__column {
    position: relative;
    height: 0;
    padding-top: -10rem;
    padding-bottom: 47%;
    margin: -8rem auto 5rem;
    z-index: 99;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include media($b-medium) {
    padding-bottom: 0;

    .grid__column {
      padding-bottom: 56.25%; /* 16:9 */
    }
  }
}