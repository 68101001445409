nav {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  left: 0;
  right: 0;
  margin: auto;
  padding: 1.5rem 1.75rem;
  width: min(100%, $max-width);
  z-index: 2;

  .nav__logo,
  .nav__items,
  .nav__action {
    display: flex;
    flex: 1;
  }

  .nav__logo svg {
    display: flex;
    height: 2rem;
    width: 2rem;
    fill: $c-white;
  }

  .nav__items {
    gap: 1rem;
    justify-content: center;

    a { color: $c-white; }
    img {  transition: transform .2s; }

    .nav__sublinks {
      padding-top: 0.5rem;
    }

    .nav__sublinks,
    .nav__items--hidden { 
      display: none; 
    } 

    .nav__dropdown:hover {
      position: relative;
  
      img {
        transform: rotate(180deg);
        transition: transform .2s;
      }
  
      .nav__sublinks {
        position: absolute;
        display: block;
        width: 20rem
      }
    }
  }

  .nav__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .nav__lang {
      color: $c-white;
      font-size: $f-small;
      cursor: pointer;
    }

    .nav__contact {
      padding: 0.25rem 2rem;
      border: 1px solid $c-white;
      margin-left: 2rem;
      border-radius: 0.2rem;
      color: $c-white;
    }

    .nav__menu svg {
      margin-left: 1.5rem;
      height: 1.25rem;
      color: $c-white;
      cursor: pointer;
    }
  }

  &.nav--dark {
    color: $c-black;

    .nav__logo, 
    .nav__items {
      display: none;
    }

    .nav__lang,
    .nav__contact,
    .nav__menu svg {
      color: $c-black;
      border-color: $c-black;
    }
  }

  @include media($b-large) {
    .nav__items--hidden-plus { display: none; }
  }

  @include media($b-medium-menufit) {
    .nav__logo { flex: 0 0 2; }   
  }

  @include media($b-medium-menu) {
    .nav__items,
    .nav__contact {
      display: none;
    }
  }
}
