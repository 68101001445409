.logo-overview {
  position: relative;
  display: flex;
  flex-direction: column;
  color: $c-white;
  background: $c-background;
  overflow: hidden;

  .logo-overview__container {
    position: relative;
    margin: 2rem auto;
    padding: 2rem 1rem;
    width: 100%;
    max-width: $max-width;

    .swiper-wrapper {
      transition-timing-function: linear;
    }

    img {
      width: 10rem;
      height: auto;
      opacity: 0.5;
    }

    &::after,
    &::before {
      position: absolute;
      content: '';
      width: 5rem;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(270deg, #16081000 0%, #160810 100%);
      z-index: 9;
    }

    &:after {
      right: 0;
      left: auto;
      background: linear-gradient(90deg, #16081000 0%, #160810 100%);
    }
  }

  @include media($b-small) {
    .grid { margin: 0; }
    .companies__container { margin: 0; }
  }
}

