.welcome {
  position: relative;

  .welcome__container {
    margin: -3rem auto 0;
    padding: 0 1.5rem 2rem;
    max-width: $max-width-small;
    text-align: center;
  }

  .welcome__container h1 img {
    width: min(22rem, 100%);
  }

  .welcome__tagline {
    margin: 0.5rem auto 1rem;
    max-width: calc($max-width-small - 10rem);
  }

  .welcome__options {
    display: flex;
    flex-direction: column;
  }

  .welcome__options h3 {
    margin: 3rem 0 1.5rem;
  }

  iframe {
    width: auto;
    height: 15rem;
    margin: 1rem 0;
  }

  .button + .button {
    margin-left: 0;
  }

  &:before,
  &:after {
    @include pseudo-base;
    top: 8rem;
    height: calc(100% - 8rem);
    min-height: calc(100vh - 8rem);
    background: linear-gradient(15deg, #5a233b 0%, #FFFFFF00 31%),linear-gradient(348deg, #bd7b4c 0%, #a75a4b 13%, #FFFFFF00 41%),linear-gradient(0deg, #DBDADAFF 35%, #FFFFFF00 86%),radial-gradient(75% 75% at 50% 50%, #FFFFFFFF 0%, #FFFFFFFF 100%);
    opacity: 0.5;
    z-index: -1;
  }

  &:after {
    animation: fade-in-out 5s infinite;
    background: linear-gradient(5deg, #a75a4b 0%, #72363f 21%, #FFFFFF00 44%),linear-gradient(352deg, #5a233b 0%, #FFFFFF00 32%),linear-gradient(353deg, #2C292E5C 0%, #FFFFFF00 19%),linear-gradient(0deg, #DBDADAFF 29%, #073AFF00 76%),radial-gradient(75% 75% at 50% 50%, #FFFFFFFF 0%, #FFFFFFFF 100%);
  }

  @include media($b-small) {
    &:before,
    &:after {
      height: 120vh;
    }
  }
}
