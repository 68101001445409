.experience {
  position: relative;
  padding: 5rem 0 10rem;
  color: $c-white;
  padding-top: 5rem;
  background: $c-background;
  z-index: 1;

  p {
    max-width: 30rem;
    margin: 1rem auto 0;
  }

  .experience__label {
    color: $c-tertiary2;
    padding-bottom: 1rem;
    font-family: 'Intel Bold', Helvetica, sans-serif;
  }

  .experience__title {
    text-align: center;
    max-width: 80rem;
  }

  .experience__highlight {
    display: none;
    position: relative;
    margin-bottom: 2rem;
    padding: 1rem 12rem 2rem 2rem;
    font-family: 'Intel Bold', Helvetica, sans-serif;
    font-weight: bold;
    background: linear-gradient(308deg, #A6808C6B 0%, #A50A476B 62%);
    border-radius: 1rem;

    &::after {
      content: '🕹️';
      position: absolute;
      font-size: 10em;
      top: -6rem;
      right: 1rem;
    }

    span {
      background: linear-gradient(292deg, #A50A4730 1%, #FF006B91 100%),radial-gradient(75% 75% at 50% 50%, #EDBFD0CF 1%, #F395BDFF 100%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  &::before {
    @include pseudo-base;
    height: 50rem;
    background: radial-gradient(30% 30% at 35% 38%, #DEB6AB1F 0%, #DEB6AB00 100%),radial-gradient(30% 30% at 28% 64%, #A50A473D 0%, #A50A4700 96%),radial-gradient(30% 30% at 70% 38%, #A50A4721 0%, #A50A4700 96%),radial-gradient(30% 30% at 55% 64%, #160810FF 32%, #1608102B 99%),linear-gradient(0deg, #160810FF 0%, #2e1323 100%);
    z-index: -1;
  }

  &::after {
    @include pseudo-base;
    background: url('../images/background/digitalexperiences.png') no-repeat;
    background-size: max(20%, 20rem) auto;
    top: 1rem;
    left: -0.5rem;
    opacity: 0.5;
    z-index: -1;
  }

  @include media($b-medium) {
    padding: 5rem 0 3rem;

    .experience__title {
      padding-bottom: 0;
    }

    .experience__title p {
      display: none;
    }

    .experience__highlight {
      display: block;
    }
  }

  @include media($b-small) {
    .experience__highlight { padding: 2rem }
    .experience__highlight::after { display: none }
  }
}
