@keyframes fade-in-out {
  0% { opacity: 0.5; }
  50% { opacity: 0; }
  100% { opacity: 0.5; }
}

@keyframes fade-in-out-smooth {
  0% { opacity: 0.5; }
  50% { opacity: 0.15; }
  100% { opacity: 0.5; }
}

@keyframes mouse-wheel {
	to {
		opacity: 0;
		top: 32px;
	}
}