// Primary
$c-primary1: #3b1a29; // Toledo
$c-primary2: #160810; // Coffeebean

// Secondary
$c-secondary1: #5a233b; // Wine Berry
$c-secondary2: #72363f; // Buccaneer
$c-secondary3: #a75a4b; // Matrix
$c-secondary4: #bd7b4c; // Twine
$c-secondary5: #e7b95d; // Porsche

// Tertiary
$c-tertiary1: #f1f171; // Manz
$c-tertiary2: #a50a47; // Jazzberry

// Highlights
$c-hightlight1: #07387d; // Catalina Blue
$c-hightlight2: #647d08; // Yukon Gold
$c-hightlight3: #7d1407; // Dark Burgundy

// Background
$c-background: $c-primary2; // Coffeebean

// Basics
$c-black: #000;
$c-white: #fff;

// Grayscale
$c-greyscale-01: #736B70; // Fedora
$c-greyscale-02: #A29C9F; // Shady Lady
$c-greyscale-03: #D5CCD1; // Pale Slate
$c-greyscale-04: #F4F2F3; // Concrete
