header {
  position: relative;
  padding: 4rem 0 3.5rem;
  height: calc(100vh - 3rem);
  color: $c-white;
  background: radial-gradient(75% 75% at 45% 110%, $c-secondary1 0%, #27111D 100%);
  z-index: 1;

  h1 {
    text-align: center;
    padding-bottom: 1.5rem;
    max-width: 45rem;
  }

  .header__avatar {
    img {
      @include pseudo-base;
      width: 4.25rem;
      height: 4.25rem;
      top: calc(100% - 2.5rem);
      left: calc(50% - 2.025rem);
      border: 4px solid $c-white;
      border-radius: 10rem;
      z-index: 99;
    }

    &::after {
      @include pseudo-base;
      @include center-horizontal;
      width: 10rem;
      height: 5rem;
      top: calc(100% - 4.1rem);
      background: url('../images/background/top-bow-inverted.svg') no-repeat center;
      background-size: 10rem;
    }
  }

  &::before {
    @include pseudo-base;
    background: url('../images/background/cross.png');
    opacity: 0.08;
    z-index: -1;
  }
  
  &::after {
    @include pseudo-base;
    height: 60%;
    top: 40%;
    background: url('../images/background/blocks.png') no-repeat center;
    z-index: -1;
  }

  // Homepage
  &.header--cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 65rem;

    h1 {
      max-width: 50rem;
      font-size: 3.8rem;
      line-height: 1.3;
      margin: 2rem auto 0;
    }

    p {
      max-width: 40rem;
      margin: 0 auto 3rem;
      font-size: $f-large;
      font-family: 'Intel Bold', Helvetica, sans-serif;
      text-align: center;
    }
  }

  // Product & Development
  &.header--image {
    height: auto;
    padding: 5.5rem 0 3.5rem;

    .availability { 
      margin: 2rem 0 0.5rem;
    }

    h1 { 
      max-width: 55rem;
      text-align: left;
    }

    p {
      font-family: 'Intel Bold', Helvetica, sans-serif;
      font-size: $f-large;
      padding-bottom: 4rem;
    }
  }

  &.header--dark {
    height: auto;
    padding: 5.5rem 0 3.5rem;

    h1, p { 
      margin: 0;
      text-align: left;
    }

    .availability { 
      margin: 2rem 0 0.5rem;
    }
  
    &::before {
      opacity: 0.09;
    }   
  }

  &.header--light {
    overflow: hidden;
    top: -1rem;
    padding: 0;
    height: 12rem;
    background: url('../images/background/top.png') center no-repeat;
    background-position: 50% -8rem;
    background-size: 25rem;
    z-index: -1;
    
    .header__top {
      height: 6rem;
      background: url('../images/background/top-bow.svg') no-repeat;
      background-position: top center;
      opacity: 0.8;
    }

    .header__avatar img {
      top: 3.75rem;
      left: calc(50% - 2.1rem);
    }

    &::before,
    &::after,
    .header__avatar::after {
      display: none;
    }
  }

  @include media($b-medium-plus) {
    &.header--cover { 
      h1 { font-size: 2.8rem;}
    }
  }

  @include media($b-small) {
    &.header--cover { 
      height: auto;
      h1 { font-size: 1.75rem;}
    }

    &.header--image {
      p { padding-bottom: 1rem; }
    }
   
    h2 { display: none; }
  }
}
