.intro {
  position: relative;
  color: $c-white;
  padding-top: 6rem;
  background: $c-background;
  overflow: hidden;

  h2 { 
    font-size: $f-large;
    font-weight: bold;
    margin-bottom: 1rem; 
  }

  q {
    font-size: 1.75rem;
    font-family: 'Intel Bold', Helvetica, sans-serif;
    width: 10rem;
  }
  
  p { margin-bottom: 1rem; }

  img {
    width: 65%;
    margin: 1.5rem;
    aspect-ratio: 413/368;
    opacity: 0.25;
  }

  label + p {
    margin-top: 1rem;
  }

  &::after {
    @include pseudo-base;
    top: calc(100% - 4rem);
    left: min(calc(100dvw - 23rem), 120rem);
    background: url('../images/background/product.png') no-repeat;
    background-size: 25rem 4rem;
    opacity: 0.1;
  }

  @include media($b-medium) {
    .grid__column:first-child {
      display: none;
    }

    p { margin-bottom: 2rem; }

    &::after {
      background-size: 15rem;
      left: calc(100vw - 15rem);
      top: calc(100% - 3rem);
    }
  }
}
