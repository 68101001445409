.faq {
  padding-bottom: 10rem;
  color: $c-white;
  background-color: $c-background;

  .grid__column {
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    padding-bottom: 2rem;
  }

  .faq__item {
    font-size: $f-large;
    max-width: 35rem;
    color: $c-white;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid rgba($c-greyscale-04, 0.75);

    .faq__question {
      padding: 1rem 2rem 1rem 0;
      color: $c-white;
    }

    .faq__answer {
      display: none;
      font-size: $f-normal;
      font-family: 'Intel Medium';
      padding: 1rem 2rem 2rem 0;
      color: $c-greyscale-03;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .faq__item.faq__item--active {
    .faq__answer {
      display: block;
    }
  }

  @include media($b-small) {
    padding-bottom: 7.5rem;
  }
}
