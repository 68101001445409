@mixin font-face($family, $path, $style: normal, $weight: normal) {
  @font-face {
    font-family: '#{$family}';
    src: url('#{$path}.woff') format('woff');
    font-style: $style;
    text-rendering: optimizeLegibility;
    font-display: fallback;
  }
}

// Primary
@include font-face('Proxima Nova Regular', '../fonts/proxima-nova/proxima-nova-regular');
@include font-face('Proxima Nova Bold', '../fonts/proxima-nova/proxima-nova-bold');
@include font-face('Proxima Nova Italic', '../fonts/proxima-nova/proxima-nova-italic');

// Headings
@include font-face('Sofia Pro', '../fonts/sofia-pro/sofia-pro-bold');
@include font-face('Intel Bold', '../fonts/intel/intel-bold');

// Accent
@include font-face('Intel Medium', '../fonts/intel/intel-medium');
@include font-face('Squere Peg', '../fonts/square-peg/square-peg-regular');

// Font sizes
$f-largest: 2.5rem;
$f-larger: 2rem;
$f-large: 1.25rem;
$f-normal: 1rem;
$f-small: 0.9rem;
$f-smaller: 0.75rem;

// Default
body {
  font-family: 'Proxima Nova Regular', Helvetica, sans-serif;
  font-size: $f-normal;
  line-height: 2;

  b { font-family: 'Proxima Nova Bold', Helvetica, sans-serif; }
  i { font-family: 'Proxima Nova Italic', Helvetica, sans-serif; }

  p + p { margin-top: 1rem; }
}

// Headings
h1 {
  font-family: 'Sofia Pro', Helvetica, sans-serif;
  font-size: $f-largest;
}

h2 {
  font-family: 'Intel Bold', Helvetica, sans-serif;
  font-size: $f-larger;
}

h3 {
  font-family: 'Intel Bold', Helvetica, sans-serif;
  font-size: $f-large;
}

h4 {
  font-family: 'Intel Bold', Helvetica, sans-serif;
}

// Specials
label {
  color: $c-tertiary2;
  font-family: 'Intel Medium', Helvetica, sans-serif;
}

@include media($b-medium) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }
}
