.transformation {
  color: $c-white;
  padding-top: 2rem;
  background: linear-gradient(0deg, #1D0C1600 0%, #160810 100%),radial-gradient(65% 65% at 25% 27%, #67374A 0%, #28121E 100%);

  li {
    position: relative;
    margin: 0.5rem 0 0.2rem 2rem;

    &:before {
      content: '✓';
      position: absolute;
      font-size: 30;
      left: -2rem;
      top: -0.75rem;
    }
  }
}