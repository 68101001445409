.highlights {
  height: 20rem;
  background: radial-gradient(30% 30% at 35% 38%, #DEB6AB1F 0%, #DEB6AB00 100%),radial-gradient(30% 30% at 28% 64%, #A50A473D 0%, #A50A4700 96%),radial-gradient(30% 30% at 70% 38%, #A50A4721 0%, #A50A4700 96%),radial-gradient(30% 30% at 55% 64%, #160810FF 32%, #1608102B 99%),linear-gradient(0deg, #160810FF 0%, #2e1323 100%);

  .grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .div1 { grid-area: 1 / 1 / 2 / 3; }
  .div2 { grid-area: 1 / 3 / 2 / 5; }
  .div3 { grid-area: 2 / 1 / 4 / 3; }
  .div4 { grid-area: 2 / 3 / 4 / 5; }
  .div5 { grid-area: 1 / 5 / 3 / 7; }
  .div6 { grid-area: 3 / 5 / 4 / 7; }

  .div1 {
    background: $c-secondary1;
  }
  .div2 {
    background: $c-secondary2;
  }
  .div3 {
    background: $c-secondary3;
  }
  .div4 {
    background: $c-secondary4;
  }
  .div5 {
    background: $c-secondary3;
  }
  .div6 {
    background: $c-secondary2;
  }
}

  