.services {
  position: relative;
  color: $c-white;
  padding: 2rem 0;
  background: linear-gradient(0deg, #1D0C1600 0%, #160810 100%),radial-gradient(65% 65% at 25% 27%, #67374A 0%, #28121E 100%);
  overflow: hidden;
  z-index: 1;

  h2 {
    max-width: 45rem;
    margin-bottom: 1rem;
  }

  &::after {
    @include golden-ratio;
  }

  .services__showcase {
    @include blinking-dot;
    position: relative;
    margin: 0 auto;
    padding: 0 1rem; 
    max-width: $max-width;
  }

  .services__container {
    display: flex;
    position: relative;
    flex-direction: row;

    &.services__container--services,
    &.services__container--companies {
      .services__title {
        min-width: 15rem;
      }

      .services__links {
        display: flex;
        position: relative;
        gap: 1rem;

        h3, p { margin-bottom: 2rem; }
      }

      .services__service {
        display: flex;
        flex-basis: 100%;
        width: 33%;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;
        color: $c-white;
        flex-direction: column;
        min-width: 14rem;
        background-color: $c-background;
        border-radius: 0.5rem;
        z-index: 1;
      }
    }

    &.services__container--services {
      .services__title {
        padding: 1rem 0 0 2.5rem;
      }

      .services__links {
        padding: 5rem 0 8rem 5%;
        margin-left: 0.5rem;
      }

      &:before {
        position: absolute;
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        top: 1.5rem;
        left: -.15rem;
        border-radius: 50%;
        background: $c-tertiary2;
        z-index: 1;
      }

      &:after {
        @include border-radient;
        content: '';
        position: absolute;
        width: 23%;
        height: 100%;
        left: 0.5rem;
        top: 0;
        border-left: solid 3px transparent;
        border-bottom: solid 3px transparent;
        border-bottom-left-radius: 10px;
        background: linear-gradient(to right, $c-tertiary2, $c-secondary3) border-box;
      }
    }
    
    &.services__container--companies {
      flex-direction: column;
      width: 35%;

      .services__title {
        text-align: right;
        padding: 2rem 2.5rem 0 0;
      }

      .services__links {
        width: 100%;
        padding: 2.5rem 8rem 1rem 2rem;
      }

      .services__service {
        padding: 3rem 1.5rem;
        max-width: 20rem;
      }

      &:before {
        position: absolute;
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        top: 2.5rem;
        right: -.65rem;
        border-radius: 50%;
        background: $c-secondary5;
        z-index: 1;
      }

      &:after {
        @include border-radient;
        content: '';
        position: absolute;
        width: 10rem;
        height: 100%;
        left: calc(100% - 10rem);
        top: -2.6px;
        border-right: solid 3px transparent;
        border-top: solid 3px transparent; 
        border-top-right-radius: 10px;
        background: linear-gradient(to right, $c-secondary3, $c-secondary5) border-box;
      }
    } 
  }

  @include media($b-medium-plus) {
    .services__showcase::before {
      left: calc(100% - 1.75rem);
    }

    .services__showcase::after {
      left: calc(100% - 2rem);
    }

    .services__container {
      flex-direction: column;

      .services__links {
        flex-wrap: wrap;
        text-align: center;
      }

      .services__service {
        justify-content: center;
        min-height: 10rem;
      }

      &.services__container--services,
      &.services__container--companies {
        width: 100%;
      }

      &.services__container--services .services__links {
        padding: 2.5rem 0 2.5rem 2rem;
      }

      &.services__container--companies .services__links {
        padding: 2.5rem 2.5rem 1rem 0;
        justify-content: flex-end;
      }

      &.services__container--services::after {
        width: 85%;
      }
    }
  }

  @include media($b-small) {
    .services__container {
      &.services__container--services::after {
        width: 60%;
      }
    }

    &.services__container--companies .services__links .services__service {
      max-width: auto;
    }
  }
}
