.tiles {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;

  &.tiles--development .tiles__tile {
    width: calc(33% - 0.66rem);
  }

  &.tiles--product .tiles__tile,
  &.tiles--development .tiles__tile:nth-child(-n+2) {
    width: calc(50% - 0.5rem);
  }

  &.tiles--slider {
    display: none;
  }

  .tiles__tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
    text-align: center;
    background: rgba($c-background, 0.9);
    border-radius: 1rem;
  }

  // Can be eitheran emoji or image
  .tiles__icon {
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    line-height: 1.1;
    
    img {
      width: 3rem;
      height: 3rem;
      object-fit: contain;
    }
  }

  .tiles__text {
    padding-top: 0.5rem;
    line-height: 1.5;
  }

  .tiles__company {
    font-size: $f-smaller;
  }

  @include media($b-medium) {
    display: block; 
    width: calc(100% + 1.5rem); // Break out of content
    margin: 0 0 -1.5rem;

    &.tiles--slider {
      display: block;
    }

    &.tiles--product,
    &.tiles--development {
      display: none;
    }

    .swiper {
      padding-bottom: 5rem;
  
      .swiper-slide {
        display: flex;
        flex-wrap: wrap;
        height: auto;
      }
  
      .swiper-button-prev {
        display: none;
      }
  
      .swiper-button-next {
        width: 3rem;
        height: 3rem;
        margin-top: 3rem;
        margin-right: 2rem;
        border-radius: 5rem;
        background: $c-tertiary2;
  
        &::before {
          @include pseudo-base;
          content: url(../images/icons/arrow.svg);
          top: 0.75rem;
          left: 0.75rem;
          transition: 0.2s ease;
        }
  
        &::after {
          font-family: 'Raleway Bold', Helvetica, sans-serif;
          font-size: $f-small;
          color: white;
          margin-left: -0.2rem;
          margin-bottom: 4rem;
          transform: rotate(-90deg);
          transition: 0.2s ease;
        }
  
        &:hover {
          &::before {
            scale: 1.25;
            top: 0.9rem;
            left: 0.9rem;
            transition: 0.2s ease;
          }
  
          &::after {
            margin-bottom: 4.5rem;
            transition: 0.2s ease;
          }
        }
      }
    }
  }
}
