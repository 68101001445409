.homepage {
  header .grid {
    flex-direction: column;
  }

  .logo-overview {
    //display: none; // Remove when logos are added
    padding: 8rem 0 4rem;

    &::before {
      @include pseudo-base;
      top: 1rem;
      left: min(calc(100dvw - 23rem), 120rem);
      background: url('../images/background/growth.png') no-repeat;
      background-size: 25rem 4rem;
      opacity: 0.1;
    }
  }

  // Remove .services when logos are added
  .services {
    &::before {
      @include pseudo-base;
      top: 1rem;
      left: min(calc(100dvw - 23rem), 120rem);
      background: url('../images/background/growth.png') no-repeat;
      background-size: 25rem 4rem;
      opacity: 0.1;
    }
  }

  @include media($b-medium-plus) {
    .logo-overview {
      display: none; // Remove when logos are added
      padding: 1rem 0;

      &::before {
        background-size: 15rem;
        left: calc(100vw - 15rem);
        top: 0rem;
      }
    }
  }

  // Remove whole when logos are added
  @include media($b-medium) { 
    .services {
      &::before {
        background-size: 15rem;
        left: calc(100vw - 15rem);
        top: 0rem;
      }
    }
  }

  @include media($b-medium) { 
    .socials {
      .socialspost:nth-child(2) {
        a:nth-child(2), a:last-child { display: none; }
        a:first-child { display: inline-flex; }
      }

      .socialspost:nth-child(2) {
        a:first-child { display: inline-flex; }
      }
    }
  }
}



