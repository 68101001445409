.testimonials {
  padding: 5rem 0 3rem;
  background: radial-gradient(49% 81% at 45% 47%, #1E1216FF 0%, #073AFF00 100%),radial-gradient(113% 91% at 17% -2%, #28121E 1%, #FF000000 99%),radial-gradient(142% 91% at 83% 7%, #28121E 1%, #FF000000 99%),radial-gradient(142% 91% at -6% 74%, #23131BFF 1%, #291120 99%),radial-gradient(142% 91% at 111% 84%, #291120 1%, #291120 100%);

  .grid .grid__column {
    display: flex;
    gap: 1rem;
  }

  .testimonial {
    position: relative;
    padding: 1.5rem 1rem 1rem;
    background-color: $c-white;
    border-left: 10px solid $c-secondary3;
    border-radius: 0.5rem;

    &:nth-child(2) {
      border-left: 10px solid $c-secondary4;
    }

    &:nth-child(3) {
      border-left: 10px solid $c-secondary5;
    }

    &:first-letter {
      padding-left: 2rem;
    }

    &:before {
      content: '"';
      font-family: "Dancing Script", serif;
      position: absolute;
      font-size: 10rem;
      top: -5rem;
      left: -0.5rem;
    }
  }

  @include media($b-medium-plus) {
    .testimonial:nth-child(3) { display: none; }
    .testimonial { width: 100%; }
  }

  @include media($b-small) {
    .grid .grid__column {
      flex-direction: column;
    }
  }
}