.menu {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  z-index: 99;

  .menu__overlay {
    width: calc(100% - $max-width-small);
    background-color: rgba(black, 0.9);
  }

  .menu__container {
    width: $max-width-small;
    background-color: $c-white;
    overflow-y: scroll;
    z-index: 1;
  }

  .menu__content {
    position: relative;
    margin-top: -3rem;
    padding: 0 2rem;
    text-align: center;
  }

  .menu__nav {
    display: none;
    text-align: left;
    flex-direction: column;
    padding-bottom: 2rem;
    border-bottom: 1px solid $c-greyscale-04;

    li { margin-bottom: 0.5rem;}
    a { color: $c-black; }

    .nav__sublinks {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem 0;
    }
  }

  .menu__content h2 {
    font-family: 'Sofia Pro', Helvetica, sans-serif;
    font-size: $f-largest;
  }

  .menu__tagline {
    font-size: $f-large;
  }

  .menu__subtitle {
    font-size: $f-smaller;
    color: $c-greyscale-02;
    margin: 0.75rem 0 1.5rem;
  }

  .menu__intro {
    margin: 2rem 0;
  }

  .menu__availability,
  .menu__work {
    padding: 1rem 0 2rem;
    border-top: 1px solid $c-greyscale-04;
  }

  .menu__intro,
  .menu__work,
  .menu__timeline {
    text-align: left;
  }

  .menu__close {
    display: block;
    position: absolute;
    right: 1rem;
    top: 2.4rem;
    z-index: 999;

    &:hover {
      cursor: pointer;
    }
  }

  .menu__close img {
    width: 2rem;
    height: 2rem;
  }

  &.closed {
    display: none;
  }

  @include media($b-medium-menu) {
    .menu__nav { display: flex; }
    .menu__content h2 { margin-top: 2rem; }
  }
}

.menu--open {
  overflow: hidden;
}
