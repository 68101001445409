.contact {
  color: $c-white;
  text-align: center;
  padding: 3rem 0 5rem;
  background: linear-gradient(0deg, #160810 0%, #16081036 100%),radial-gradient(50% 50% at 41% 58%, #67374A40 0%, #160810FF 100%),linear-gradient(0deg, #160810 0%, #160810 100%);

  p { 
    margin: 0 auto;
    padding: 1rem 0 2.5rem;
    max-width: 50rem;
  }
}