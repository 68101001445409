.context {
  color: $c-white;
  text-align: center;
  padding: 2rem 0 10rem 3rem;
  background: linear-gradient(to top, #160810, #28121e) 1;

  h2, p { margin-bottom: 1rem; }
  button { margin-top: 1rem;}

  @include media($b-medium-plus) {
    padding: 2rem 1rem 5rem;
    text-align: right;

    .grid__column.grid__column--four {
      width: 100%;
    }
  }
}