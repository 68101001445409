$b-small: 480px; // Mobile (320px - 480px)
$b-medium: 768px; // Tablet (481px - 768px)
$b-medium-plus: 1024px; // Small screens and laptops (769px — 1024px)
$b-large: 1200px; // Desktops (1025px — 1200px)

$b-medium-menu: 830px;
$b-medium-menufit: 1165px;

// Desktop first - only higher up breakpoints
@mixin media($breakpoint) {
  @media screen and (max-width: #{$breakpoint}) {
    @content;
  }
}
