.timeline {
  margin-bottom: 2rem;

  .timeline__year {
    margin-bottom: 1rem;
  }

  .timeline__post {
    position: relative;
    margin-left: 2rem;

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      top: 0.2rem;
      left: calc(-1rem - 0.5px);
      border-left: 1px solid $c-secondary1;
    }

    &::before {
      content: '';
      top: 0.2rem;
      position: absolute;
      left: -1.5rem;
      height:1rem;
      width: 1rem;
      border-radius: 1rem;
      background-color: $c-secondary1;
    }

    .timeline__post-date {
      font-size: $f-small;
    }

    .timeline__post-title {
      font-size: $f-large;
      padding-bottom: 0.5rem;
    }

    .timeline__post-content {
      font-size: $f-normal;
      padding: 0.5rem 0 2rem;
    }  
  }

  .timeline__post:has(+ .timeline__year),
  .timeline__post:last-child {
    .timeline__post-content {
      padding: 0.5rem 0 0rem;
    }
  }

  .timeline__year:not(:first-child) {
    margin-top: 2rem;
  }
}
