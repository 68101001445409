.mouse-container {
	position: absolute;
	left: calc(50% -  #{1.75rem / 2});
	bottom: 7rem;
	opacity: 0.3;

	.mouse {
		position: relative;
		width: 1.75rem;
		height: 3rem;
		border-radius: 50px;
		border: 0.5px solid $c-white;
	
		&::before {
			content: '';
			position: absolute;
			width: 0.35rem;
			height: 0.35rem;
			top: 10px;
			left: 50%;
			transform: translateX(-50%);
			background: $c-white;
			border-radius: 50%;
			opacity: 1;
			animation: mouse-wheel 2s infinite;
		}
	
		@include media($b-medium) {
			display: none;
		}
	}
}
