@mixin center-horizontal {
  left: 50%;
  transform: translate(-50%);
}

@mixin pseudo-base {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@mixin golden-ratio {
  @include pseudo-base;
  top: 10rem;
  left: max(calc(100% - 80rem), 15%);
  background: url('../images/background/golden-ratio.svg') no-repeat;
  background-size: 75% auto;
  max-width: 80rem;
  opacity: 0.2;
  z-index: -1;
}

@mixin blinking-dot {
  &::before {
    position: absolute;
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    bottom: -1rem; 
    left: calc(35% - 0.4rem);
    border-radius: 50%;
    opacity: 0.4;
    background: $c-secondary5;
  }

  &::after {
    position: absolute;
    content: '';
    width: 2rem;
    height: 2rem;
    bottom: -1.4rem; 
    left: calc(35% - 0.8rem);
    border-radius: 50%;
    background: $c-secondary5;
    opacity: 0.4;
    filter: blur(0.2rem);
    z-index: -1;
  }
}

@mixin border-radient {
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@mixin faded-line {
  content: '';
  position: absolute;
  background: url('../images/background/faded-line.png') no-repeat;
  background-size: cover;
  width: 0.5px;
  opacity: 0.8;
  z-index: 1;
}