.tag-list {
  display: flex;
  flex-wrap: wrap;

  .tag {
    padding: 0.20rem 0.75rem;
    margin: 0 0.5rem 0.5rem 0;
    font-size: $f-smaller;
    font-family: 'Raleway Bold', Helvetica, sans-serif;
    color: $c-greyscale-01;
    border-radius: 3rem;
    border: 1px solid $c-greyscale-04;
  }

  .tag--small {
    font-size: 0.65rem;
    color: $c-white;
  }

  .tag--colored {
    border: none;
    color: $c-white;
  }

  .tag--colored:nth-child(4n+1) {
    background-color: $c-secondary2;
  }

  .tag--colored:nth-child(4n+2) {
    background-color: $c-secondary3;
  }

  .tag--colored:nth-child(4n+3) {
    background-color: $c-secondary4;
  }

  .tag--colored:nth-child(4n+4) {
    background-color: $c-secondary5;
  }
}
