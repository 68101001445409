.work {
  .swiper {
    margin: -4rem -2rem 0 0 !important; // Overwrite styling Swiper to break out of content
  
    .swiper-button-next,
    .swiper-button-prev {
      top: 3rem;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 2rem;
      background-color: $c-secondary1;
  
      &::after {
        color: white;
        font-size: 1rem;
      }
    }
  
    .swiper-button-next {
      right: 2rem;
      padding-left: 3px;
    }
  
    .swiper-button-prev {
      left: calc(100% - 6rem);
      padding-right: 3px;
    }
  
    .swiper-slide {
      position: relative;
      margin-top: 4rem;
  
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        height: calc(100% - 0.5rem);
        background: linear-gradient(0deg, rgba(29, 26, 35, 0.87) 5%, rgba(44, 38, 50, 0.466) 60%, rgba(204,183,174,0) 100%);
      }
    
      .swiper-slide__container {
        position: absolute;
        padding: 2rem;
        bottom: 0;
        color: $c-white;
        font-size: $f-normal;
      }
  
      h3, p {
        margin: 0;
      }
  
      img {
        width: 100%;
      }
    }
  
    @include media($b-small) {
      .swiper-slide__container p {
        display: none;
      }
    }
  }
}