.expertise {
  position: relative;
  color: $c-white;
  padding: 2rem 0;
  background: linear-gradient(0deg, #1D0C1600 0%, #160810 100%),radial-gradient(65% 65% at 25% 27%, #67374A 0%, #28121E 100%);
  overflow: hidden;
  z-index: 1;

  h2 {
    color: $c-white;
    max-width: 47rem;
    margin-bottom: 1rem
  }

  .expertise__skills {
    color: $c-tertiary2;
    font-family: 'Intel Bold', Helvetica, sans-serif;
    margin-top: 3rem;
  }

  .swiper {
    max-width: $max-width;
    overflow: visible;

    .swiper-wrapper {
      padding-top: 5rem;
      margin-left: -1rem;
    }

    .swiper-slide {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s 0.5s, opacity 0.5s linear;
    }

    .swiper-slide-next {
      opacity: 0.1;
    }

    .swiper-pagination {
      display: flex;
      width: 90%;
      position: absolute;
      text-align: left;
      top: 1.5rem;
      margin-left: -1rem;
      color: $c-white;
      z-index: -1;

      .swiper-pagination-bullet {
        position: relative;
        width: 100%;
        max-width: 12rem;
        margin-left: 3rem;
        opacity: 1;
        cursor: pointer;
        background: none;

        &::before {
          @include pseudo-base;
          content: '1';
          top: -1rem;
          left: -0.75rem;
          font-size: $f-small;
        }
   
        &:nth-child(2)::before { content: '2'; }
        &:nth-child(3)::before { content: '3'; }
        &:nth-child(4)::before { content: '4'; }
   
        &::after {
          @include pseudo-base;
          width: 2.5rem;
          height: 2.5rem;
          top: -1.25rem;
          left: -1.5rem;
          border-radius: 2rem;
          border: 1px solid $c-tertiary2;
          z-index: -1;
        }
      }

      .swiper-pagination-bullet-active {
        color: $c-tertiary2;
        transition: color 0.2s ease;
        &::before { color: $c-white; }
      }
    }

    
    .swiper-button-prev,
    .swiper-slide-duplicate-prev {
      visibility: hidden;
    }

    .swiper-slide-prev {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.5s, opacity 0.5s linear;
    }

    .swiper-button-next {
      position: absolute;
      top: -7rem;
      right: 8.5rem;

      &::after {
        position: absolute;
        color: $c-white;
        left: 2.5rem;
        top: 1.75rem;
      }

      &::before {
        @include pseudo-base;
        border: 1px solid $c-tertiary2;
        padding: 3rem;
        border-radius: 3rem;
      }
    }
    
    .swiper-content {
      padding: 1.5rem;

      h3, p {padding-bottom: 0.5rem; }

      .tag-list {
        margin-top: 1rem;

        .tag { color: $c-white; }
      }
    }
  }

  &::before {
    @include pseudo-base;
    top: 1rem;
    left: min(calc(100dvw - 23rem), 120rem);
    background: url('../images/background/growth.png') no-repeat;
    background-size: 25rem 4rem;
    opacity: 0.1;
  }

  &::after {
    @include golden-ratio;
  }

  @include media($b-medium-plus) {
    .expertise__areas .grid .grid__column--five {
      width: 100%;
    }
  }

  @include media($b-medium) {
    padding: 0;

    .expertise__skills {
      margin: 2rem 0 0 0;
    }

    .expertise__title, 
    .expertise__description, 
    .swiper .swiper-pagination,
    .swiper .swiper-button-next {
      display: none;
    }

    .expertise__areas .grid {
      padding: 0;
    }

    .swiper {
      display: flex;
      padding: 0 3.5rem;
      margin-bottom: 1.5rem;
      max-width: $max-width;

      .swiper-wrapper {
        padding-top: 1rem;
        margin-left: -0.25rem;
      }
      
      .swiper-slide {
        height: auto;
        border: 1px solid $c-tertiary2;
        border-radius: 1rem;
        transition: background-color 0.3s ease-in-out; 
      }

      .swiper-slide-prev,
      .swiper-slide-next {
        opacity: 1;
        visibility: visible;
      }

      .swiper-slide-next,
      .swiper-slide-prev {
        border: 1px solid $c-secondary1;
        background: $c-secondary1;

        h3, p, .tag-list {
          visibility: hidden;
        }
      }
    }

    .expertise__cta > .grid__column {
     display: flex;
     justify-content: center;

     .button { padding: 0.5rem 3rem; }
    }

    &::before {
      background-size: 15rem;
      left: calc(100vw - 15rem);
      top: 0rem;
    }

    &::after {
      left: 2rem;
      top: 8rem;
      background-size: 100% auto;
      opacity: 0.5;
    }
  }

  @include media($b-medium) {
    .button--dark { display: none; }
  }

  @include media($b-small) {
    .swiper .swiper-content {
      font-size: $f-small;

      .tag-list { display: none; }
    }
  }
}
