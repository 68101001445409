.socials {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $c-white;
  padding: 3rem 0 10rem;
  background: radial-gradient(60% 60% at 37% 57%, #F30C485E 0%, #160810FF 62%, #160810FF 99%),linear-gradient(0deg, #160810 0%, #160810 100%);

  h2 {
    margin-bottom: 1rem;
  }
  
  .socials__container {
    position: relative;
    display: grid;
    width: 100%;
    margin: 0 auto;
    padding: 3rem 1rem;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4);
    max-width: $max-width;

    .socialspost:nth-child(1) { 
      grid-area: 1 / 1 / 2 / 3; 
      a:nth-child(2), a:last-child { display: none; }
    }

    .socialspost:nth-child(2) { 
      grid-area: 1 / 3 / 2 / 5;
      a:first-child, a:last-child { display: none; }
    }

    .socialspost:nth-child(3) { 
      height: 100%;
      width: 100%;
      grid-area: 1 / 5 / 5 / 7;
      a:first-child, a:nth-child(2) { display: none; }
    }

    .socials__title { grid-area: 2 / 1 / 3 / 5; }
    .socials__page:nth-child(1) { grid-area: 3 / 1 / 4 / 2; }
    .socials__page:nth-child(2) { grid-area: 3 / 2 / 4 / 3; }
    .socials__page:nth-child(3) { grid-area: 3 / 3 / 4 / 4; }
    .socials__page:nth-child(4) { grid-area: 3 / 4 / 4 / 5; }
    .socials__content {  grid-area: 4 / 1 / 5 / 5; }
  }
  
  .socialspost {
    position: relative;
    height: 15rem;

    .socialspost__number {
      position: absolute;
      left: -0.25rem;
      top: 1rem;
      font-size: 1rem;
      font-family: 'Intel Bold', Helvetica, sans-serif;
      color: $c-tertiary2;
      z-index: 2;
    }

    .socials__experience {
      label {
        position: absolute;
        font-size: 10rem;
        font-family: 'Bitter', Helvetica, sans-serif;
        font-weight: 800;
        letter-spacing: -25px;
        bottom: 10rem;
        left: -4rem;
        z-index: 3;
      }

      p {
        position: absolute;
        font-family: 'Intel Bold', Helvetica, sans-serif;
        bottom: 12rem;
        left: 3rem;
        width: 70%;
        z-index: 3;
      }
    }

    &:before,
    &:after {
      @include faded-line;
      left: 0;
      top: -5rem;
      height: 160%;
    }
    &:after { left: 0.5rem; }
    &:nth-child(3):before,
    &:nth-child(3):after {
      top: -13rem;
      height: 160%;
    }
  }

  .socials__title {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-family: 'Intel Bold', Helvetica, sans-serif;
    font-size: 1.6rem;
  }

  .socials__social-post img {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
    padding: 0 0.25rem 2rem 0;
    opacity: 0.5;
    object-fit: cover;
    transition: all .2s;

    &:hover {
      transform: scale(1.01);
      transition: all .2s;
    }
  } 
   
  .socials__content {
    grid-area: 4 / 1 / 5 / 5;
    margin-top: -1rem;
    margin-right: -9rem;
    z-index: 2;
  }

  @include media($b-medium-plus) {
    .socials__experience { display: none; }
    
    .socials__container { 
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include media($b-medium) {
    padding: 2rem 0 4rem;
    h2 {
      margin-bottom: 0;
      text-align: left;
      span { display: none; } 
    }

    .socialspost:nth-child(3) { display: none; }
    .socials__content { margin: 0; }
  }

  @include media($b-small) {
    .socials__container {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 1rem;
    }

    .socials__social-post:nth-child(n+6) {
      display: none;
    }
  }
}

// Substack feed widget
.substack-feed-widget .p-2 { 
  padding: 0 0 0 1rem !important;
  transition: all .2s;
  z-index: 2;

  &:hover {
    transform: scale(1.01);
    transition: all .2s;
    z-index: 2;
  }
}

.substack-feed-widget .rounded {
  border-radius: 0 !important;
}

.substack-feed-widget .pb-\[56\.25\%\] {
  position: relative;
  padding-bottom: 240px !important;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, $c-background, $c-secondary1);
    opacity: .8; 
  }
}

.substack-feed-widget .bg-center {
  background-position: 0% !important;
}

.substack-feed-widget .sfw-title {
  margin: -13rem 1rem 1rem -1.25rem !important;
  font-size: 1.75rem !important;
  line-height: 2.5rem !important;
  font-family: 'Intel Bold', Helvetica, sans-serif;
  z-index: 2;
}

.substack-feed-widget .tracking-wider {
  margin: -3.25rem 1rem 1rem;
  z-index: 2;
}

.socialspost:nth-child(3) { 
  .substack-feed-widget .sfw-title {
    margin: -38.5rem 1rem 1rem -1.25rem !important;
  }

  .substack-feed-widget .pb-\[56\.25\%\] {
    padding-bottom: 650px !important;
  }

  .substack-feed-widget .tracking-wider {
    visibility: hidden;
  }
}

@include media($b-small) {
  .substack-feed-widget {
    width: calc(100vw - 3rem) !important;
    border-radius: 0 !important;
    overflow: hidden;
  }
}
