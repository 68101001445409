// Links
.link {
  text-decoration: underline;
  color: $c-greyscale-02;

  &.link--large {
    display: flex;
    color: $c-white;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
  }
}

// Buttons
.button {
  cursor: pointer;
  margin-bottom: 1rem;

  &.button--small {
    padding: 0.3rem 1.5rem;
    font-size: $f-small;
    border-radius: 1rem;
    color: $c-white;
    background-color: $c-secondary3;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }

  &.button--long {
    padding: 1rem;
    font-size: $f-normal;
    border: 0;
    border-radius: 2rem;
    color: $c-white;
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 10px;
    background-color: $c-primary1;
    transition: 0.2s;

    &:hover {
      background-color: $c-secondary1;
      transition: 0.2s;
    }
  }

  &.button--dark {
    padding: 0.6rem 1.6rem;
    font-size: $f-large;
    border: 0;
    border-radius: .5rem;
    color: $c-white;
    background: $c-primary2;
    transition: 0.2s;

    &:hover {
      filter: brightness(120%);
      transition: 0.2s;
    }
  }

  &.button--light {
    padding: 0.5rem 1.5rem;
    font-size: $f-large;
    border: 0;
    border-radius: .5rem;
    color: $c-white;
    background: $c-tertiary2;
    transition: 0.2s;

    &:hover {
      filter: brightness(110%);
      transition: 0.2s;
    }
  }

  &.button--form {
    margin: 0;
    padding: 1rem 1.5rem;
    border: 0;
    border-radius: 0.5rem;
    color: $c-white;
    background: $c-tertiary2;
    transition: 0.2s;

    &:hover {
      filter: brightness(110%);
      transition: 0.2s;
    }
  }
}

.button + .button {
  margin-left: 1rem;
}

// Social buttons
.share {
  margin-top: 2rem;

  .share__item {
    margin-right: 0.25rem;
    padding: 0.75rem 0.6rem 0.25rem;
    border-radius: 10rem;
    border: 1px solid $c-greyscale-04;
    background-color: $c-white;
    transition: 0.2s;
    
    img { 
      width: 1rem;
      transition: 0.2s;
    }

    &:hover {
      background-color: $c-secondary1;
      border: 1px solid $c-secondary1;
      transition: 0.3s;

      img {
        filter: brightness(0) invert(1);
        transition: 0.3s;
      }
    }
  }
}
